var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-container"},[_c('the-header'),_c('div',{staticClass:"classification-section"},[_c('div',{staticClass:"tabs-container"},_vm._l((_vm.tabs),function(tab){return _c('button',{key:tab,class:['tab-button', { active: _vm.activeTab === tab }],on:{"click":function($event){return _vm.fetchIndicators(tab)}}},[_vm._v(" "+_vm._s(tab)+" ")])}),0)]),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"preview-section"},[_vm._m(0),_c('div',{staticClass:"preview-content"},[_c('div',{staticClass:"content-section"},[_c('h2',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.activeTab))]),_c('p',{staticClass:"section-description"},[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi. ")])]),_c('div',{staticClass:"indicators-section"},[_c('div',{staticClass:"indicators-header"},[_c('span',{staticClass:"indicators-title"},[_vm._v("Key Indicators")]),_c('div',{staticClass:"data-source"},[_c('span',[_vm._v("Data Source:")]),_c('span',{staticClass:"source-name"},[_vm._v(_vm._s(_vm.dataSourceName))])])]),_c('div',{staticClass:"indicators-list"},[(_vm.loading)?_c('div',{staticClass:"loading-container"},[_c('div',{staticClass:"loading-spinner"},_vm._l((4),function(n){return _c('div',{key:n,staticClass:"spinner-segment"})}),0),_c('p',{staticClass:"loading-text"},[_vm._v("Loading indicators...")])]):(_vm.indicators.length === 0)?_c('div',{staticClass:"text-center mt-4"},[_c('p',{staticClass:"text-muted"},[_vm._v("No indicator found.")])]):_c('div',_vm._l((_vm.indicators),function(indicator,index){return _c('div',{key:index,staticClass:"indicator-item"},[_c('div',{staticClass:"indicator-row"},[_c('div',{staticClass:"indicator-info"},[_c('i',{class:[
                        'status-icon',
                        `bi ${
                          indicator.status === 'warning'
                            ? 'bi-exclamation-circle text-danger'
                            : 'bi-exclamation-circle text-secondary'
                        }`,
                      ]}),_c('span',{staticClass:"indicator-name"},[_vm._v(_vm._s(indicator.name))])]),_c('div',{staticClass:"indicator-value"},[_vm._v(_vm._s(indicator.value))])]),(index < _vm.indicators.length - 1)?_c('div',{staticClass:"indicator-divider"}):_vm._e()])}),0)])]),_c('button',{staticClass:"view-more-btn"},[_vm._v("View More")])])]),_vm._m(1)]),_c('the-footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-header"},[_c('div',{staticClass:"preview-title"},[_vm._v("Indicator Preview")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image-section"},[_c('img',{staticClass:"minister-image",attrs:{"src":require("@/assets/img/Prof.Ali_Minister_of_Health.jpg"),"alt":"Prof. Ali Minister of Health"}})])
}]

export { render, staticRenderFns }